/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'share': {
    width: 13,
    height: 12,
    viewBox: '0 0 13 12',
    data: '<path pid="0" d="M12.75 5.928L7.803.948V3.8a8.525 8.525 0 00-5.5 2.822A10.824 10.824 0 000 11.572 8.375 8.375 0 013.237 8.85a10.353 10.353 0 014.565-.863v2.889l4.948-4.948z" _fill="#9FB0C9"/>'
  }
})
